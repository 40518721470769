import * as yup from 'yup';
import { validateDocument, validateCnpj } from '../../../../../../helpers/validations';
import moment from 'moment';

export const validations = yup.object().shape({
  name: yup
    .string()
    .required('O nome é obrigatório'),
  email: yup
    .string()
    .trim()
    .email('O e-mail precisa ser válido')
    .nullable(),
  phone: yup
    .string()
    .nullable()
    .test('phone', 'O telefone precisa ser válido', value => {
      const phoneRegExp = /\(\d{2,}\)\d{9,}/;
      const homePhoneRegExp = /\(\d{2,}\)\d{8,}/;

      const isHomePhone = +value?.split(')')[1]?.trim()[0] != 9;

      if(isHomePhone) {
        return homePhoneRegExp.test(value?.replace('+55 ', '')?.replace(' ', ''));
      }

      if(value?.includes('+55')) {
        return phoneRegExp.test(value?.replace('+55 ', '')?.replace(' ', ''));
      }

      return true;
    }),
  documentId: yup
    .string()
    .nullable()
    .test('documentId', 'O CPF/CNPJ precisa ser válido', value => {
      let result = true;

      if (!value || value.length === 0) {
        result = true;
      } else if (value.length === 14) {
        result = validateDocument(value);
      } else {
        result = validateCnpj(value);
      }

      return result;
    }),
  dateOfBirth: yup
    .string()
    .nullable()
    .test('dateOfBirth', 'A data de nascimento precisa ser válida', value => {
      if(value == '--' || value == '') {
        return true;
      }

      const date = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
      return moment(value).isValid() || moment(date).isValid();
    })
});